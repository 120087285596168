import { memo, useCallback, useRef } from 'react'

import logger from '@lib/logger'
import UrlHelper from '@lib/UrlHelper'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'

const GoogleSignInButton = ({ isVisible }: { isVisible: boolean }) => {
  const formRef = useRef<HTMLFormElement>(null)

  const handleSuccess = useCallback(({ credential }: CredentialResponse) => {
    if (formRef.current && credential) {
      const form = formRef.current
      const input = form.querySelector('input[name="credential"]') as HTMLInputElement
      input.value = credential
      form.submit()
    }
  }, [])

  const handleError = useCallback(() => {
    logger.error('Error signing in with Google')
  }, [])

  // Return a placeholder div if the button is not visible
  if (!isVisible) return <div className="h-[40px] w-[182px] scale-[120%] rounded-full bg-white" />
  return (
    <>
      <div
        className="animate-breathing overflow-hidden rounded-full bg-white"
        style={{
          height: '40px',
          ['--breathing-scale-min' as string]: '1.2',
          ['--breathing-scale-max' as string]: '1.4',
        }}
      >
        <form ref={formRef} action={UrlHelper.auth.googleIDToken()} method="post" style={{ display: 'none' }}>
          <input type="hidden" name="credential" value="" />
        </form>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          logo_alignment="left"
          shape="pill"
          size="large"
          theme="outline"
          ux_mode="popup"
          type="standard"
          text="signin_with"
          use_fedcm_for_prompt
          cancel_on_tap_outside
          itp_support
          containerProps={{ style: { height: '42px' } }}
        />
      </div>
    </>
  )
}

export default memo(GoogleSignInButton)
